form .acf-fields-payfip {

    .acf-field {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .acf-label {
            width: 100%;

            label {
                margin-top: 0;
            }
        }

        .acf-error-message {
            width: 100%;
            margin-bottom: 10px;
            height: 44px;
            background: rgba(226, 0, 0, 0.15);
            color: $color-flexible-alert;
            border-left-color: $color-flexible-alert!important;
        }
    }


    input {
        height: auto;
        margin: 0 7px;
        padding: 5px 10px;

        &:first-of-type {
            margin-left: 0;
        }
    }

    // submit du formulaire     
    ~ [type=submit] {
        margin-top: 30px;
    }

    .size-2 {
        width: calc(20px * 2 + 12px);
    }

    .size-4 {
        width: calc(16px * 4 + 12px);
    }

    .size-6 {
        width: calc(14px * 6 + 12px);
    }

    .size-8 {
        width: calc(13px * 8 + 12px);
    }

    .size-13 {
        width: calc(20px * 13 + 12px);
    }
        
}

// // formulaire du protocole INDIGO-PES V2 TITRE (XXXX - XXXXXXXX - XXXXXX)
// .payfip-indigo {

// }

// // formulaire du protocole ROLMRE (Année - 00 - 00 - N° Titre = maximum 13 caractères)
// .payfip-rolmre {
    
// }

// // formulaire du protocole PES V2 ORMC (XXXX - XX - XX - XXXXXXXXXXXXX)
// .payfip-ormc {

// }


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .payfip-rolmre {
        input:nth-last-of-type(2).size-13 {
            margin-left: 0;
        }
    }

    .payfip-ormc {
        input:nth-last-of-type(2).size-13 {
            margin-left: 0;
        }
    }    

    form .acf-fields-payfip {
        .size-6 {
            margin-left: 0;
            margin-top: 10px;
        }
    }

}
