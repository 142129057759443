//======================================================================================================
// Global Colors
//======================================================================================================

// SVG
$color-svg--consultations               : $color-main; // single consultations


//======================================================================================================
// Card
//======================================================================================================
$card-consultations-v1-gap              : 0; // 0 si le padding général est égal à 0 (voir dans le fichier variables.scss)

// Background Card
$color-bg--vote-v1                      : $color-bg--date-v1;
$color-bg--vote-v2                      : $color-bg--date-v2;

// Text Card
$color--vote                            : $color-second; // Consultations card archive