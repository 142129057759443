//======================================================================================================
// Bloc Onglets
//======================================================================================================
.bloc-tabs {
    position: relative;

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__tab {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        border-radius: $border-radius $border-radius 0 0;
        border: 1px solid $color-dark;
        height: 40px;
        color: $color-text;
        font-weight: $font-weight-medium;
        line-height: 1rem;
        cursor: pointer;
        margin: 11px 10px -1px 0px;
        padding: 0 20px;

        &--active,
        &:hover,
        &:focus {
            background: $color-main;
            border-color: $color-dark;
            color: $color-second;
            transition: all $duration ease-in-out;
        }
    }

    &__panel {
        position: relative;
    }

    &__content {
        position: absolute;
        z-index: 2;
        opacity: 0;
        width: 100%;
        border-radius: $border-radius;
        background-color: $color-white;
        border: 1px solid $color-dark;
        padding: 30px;        
        z-index: -1;

        p, a, ul, ol {
            display: none;
        }


        &--active {
            animation: fadeTab 0.5s forwards;
            z-index: 3;

            p, a, ul, ol {
                display: block;
            }

        }
    }
}

/* Animation Tab */
@keyframes fadeTab {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
