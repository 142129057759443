.breadcrumb {
    background-color: $color-main;

    ul  {
        display: block;
        min-height: 60px;
        padding: 15px 0;
    }

    li {
        position: relative;
        display: inline;

        a, span {
            display: inline;
            color: $color-second;
            line-height: 1.75;
        }

        a {
            &:hover, &:focus {
                text-decoration: underline;
            }
        }

        svg {
            width: 26px;
            height: 26px;
            margin: 0 10px;
            fill: $color-second;
            vertical-align: top;
        }

    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        li svg {
            margin: 0 5px;
        } 
    }

}
